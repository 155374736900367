<template>
  <success-autonomous v-if="isAutonomous" />
  <success-company v-else />
</template>

<script>
import { mapGetters } from 'vuex'
import successAutonomous from './successAutonomous.vue'
import successCompany from './successCompany.vue'
export default {
  components: {
    successAutonomous,
    successCompany,
  },
  computed: {
    ...mapGetters({
      isOpening: 'formDataUser/isOpening',
      isAutonomous: 'formDataUser/isAutonomous',
    }),
  },
}
</script>
