<template>
  <v-row justify="center" class="s-min-height">
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="12">
          <h2 class="custom-title-secondary mb-2">
            Maravilha! Com estas informações já sabemos que você se encaixa
            exatamente em nossa área de atuação!
          </h2>
          <span v-if="isOpening" class="custom-normal-text">
            Clique em <span class="font-weight-bold"> "contratar" </span> para
            iniciar o processo de abertura da sua empresa com o Simplificador.
            <br />
            Estamos ansiosos para começar nossa parceria o quanto antes!
          </span>
          <span v-else class="custom-normal-text">
            Clique em <span class="font-weight-bold"> "contratar" </span> para
            trazer sua contabilidade para o Simplificador.
            <br />
            Estamos ansiosos para receber sua empresa o quanto antes!
          </span>
          <p class="custom-normal-text">
            Caso tenha alguma dúvida, clique em "falar com nossa equipe" para
            que um de nossos atendentes te ajude.
          </p>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col cols="12">
          <v-btn
            block
            class="custom-btn-green rounded-0 btn-large"
            @click="next()"
          >
            Contratar
          </v-btn>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : ''">
          <v-btn
            block
            class="custom-btn-green-inverse rounded-0"
            @click="help()"
          >
            Falar com nossa equipe
          </v-btn>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : ''">
          <v-btn
            block
            class="custom-btn-green-inverse rounded-0"
            @click="restart()"
          >
            Reiniciar processo
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <dialogs-contact
      :dialogShow="dialogShow"
      @close="dialogShow = $event"
    ></dialogs-contact>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
    dialogsContact: () => import('./dialogs/dialogsContact.vue'),
  },
  data() {
    return {
      dialogShow: false,
    }
  },
  computed: {
    ...mapGetters({
      isOpening: 'formDataUser/isOpening',
    }),
  },
  methods: {
    async next() {
      await this.$store.dispatch('formDataUser/goToNextStep')
      this.$router.push({ name: 'newUser' })
    },
    async restart() {
      await this.$store.dispatch('formDataUser/resetUserForm')
      this.$router.push({ name: 'quiz' })
    },
    help() {
      this.dialogShow = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@design';
.s-min-height {
  min-height: 300px;
  @media only screen and (max-width: 600px) {
    min-height: auto;
  }
}
</style>
