import { render, staticRenderFns } from "./successCompany.vue?vue&type=template&id=374fb1be&scoped=true"
import script from "./successCompany.vue?vue&type=script&lang=js"
export * from "./successCompany.vue?vue&type=script&lang=js"
import style0 from "./successCompany.vue?vue&type=style&index=0&id=374fb1be&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "374fb1be",
  null
  
)

export default component.exports