<template>
  <v-row justify="center" class="s-min-height">
    <v-col cols="12" class="">
      <v-row no-gutters>
        <v-col cols="12">
          <h2 class="custom-title-secondary mb-2">
            Ótimo! Com estas informações já sabemos um pouco mais sobre o seu
            perfil de profissional autônomo ou liberal
          </h2>
          <span class="custom-normal-text">
            Agora, para que possamos entender e indicar o modelo mais econômico
            para você ficar em dia com seu imposto de renda, indicamos que
            converse com um dos nossos especialistas
          </span>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col cols="12">
          <v-btn
            block
            class="custom-btn-green rounded-0 btn-large"
            href="https://api.whatsapp.com/send?phone=5511975386175&text=Preciso%20tirar%20d%C3%BAvidas%20com%20a%20equipe%20do%20Simplificador"
            target="_blank"
          >
            Conversar pelo whatsapp
          </v-btn>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : ''">
          <v-btn
            block
            class="custom-btn-green-inverse rounded-0"
            @click="help()"
          >
            Solicitar uma ligação
          </v-btn>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : ''">
          <v-btn
            block
            class="custom-btn-green-inverse rounded-0"
            @click="restart()"
          >
            Reiniciar processo
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <dialogs-contact :dialogShow="dialogShow" @close="dialogShow = $event" />
  </v-row>
</template>

<script>
export default {
  components: {
    dialogsContact: () => import('./dialogs/dialogsContact.vue'),
  },
  data() {
    return {
      dialogShow: false,
    }
  },
  methods: {
    async restart() {
      await this.$store.dispatch('formDataUser/resetUserForm')
      this.$router.push({ name: 'quiz' })
    },
    help() {
      this.dialogShow = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@design';
.s-min-height {
  min-height: 300px;
  @media only screen and (max-width: 600px) {
    min-height: auto;
  }
}
</style>
